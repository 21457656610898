<template>
  <div class="postList">
    <PullRefresh
      v-if="!isLoading"
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isNoData="isNoData"
      :error="error"
      className="HotRankingPage"
    >
      <PostItem
        v-for="(item, index) in videoList"
        :key="index"
        :postInfo="item"
      />
    </PullRefresh>
    <Loading v-else />
  </div>
</template>

<script>
import { queryBuyVid, queryHotInfoList } from "@/api/video";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
// import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
// import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import PostItem from "@/components/PostItem";
export default {
  name: "postList",
  components: {
    PostItem,
    PullRefresh,
    Loading,
    // VerticleThreeList,
    // TransverselyTwoList,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      videoList: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [],
      error: false,
    };
  },
  created() {
    this.getList("refresh");
  },
  methods: {
    /**
     * 我的书架数据列表
     * @param type
     * @returns {Promise<void>}
     */
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      let res = await this.$Api(queryBuyVid, req);

      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      try {
        if (res && res.code == 200) {
          if (res.data.list && res.data.list.length > 0) {
            if (type == "refresh") {
              this.videoList = res.data.list;
            } else {
              this.videoList = this.videoList.concat(res.data.list);
            }
          } else {
            this.videoList = [];
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.getList("refresh");
      // this.getHotInfoList('refresh');
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.postList {
  /deep/ .communityHead {
    margin-top: 0;
    margin-bottom: 0;
    .menuBox {
      .menuItme {
        margin-left: 0;
        padding: 0 16px;
      }
    }
  }
}
</style>
