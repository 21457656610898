<template>
  <div class="purchased">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">我的购买</div>
      <div class="rightBtn"></div>
    </div>
    <van-tabs
      class="navTabs oepnX"
      swipeable
      animated
      line-width="24"
      color="rgb(113,69,231)"
      line-height="6"
      v-model="activeName"
      background="rgba(0,0,0,0)"
      v-if="navList && navList.length"
      @change="clickTag"
    >
      <van-tab v-for="(item, index) in navList" :key="index">
        <template #title>
          <span
            class="navTabTitle"
            :class="{ navTabTitleActive: activeName == index }"
          >
            {{ item.title }}
          </span>
        </template>
        <PostList v-if="item.kind == 99" />
        <div class="bookshelfContent" v-else>
          <PullRefresh
            v-if="!isLoading"
            :loading="loading"
            :refreshing="refreshing"
            :finished="finished"
            @onLoad="onLoad"
            @onRefresh="onRefresh"
            :isNoData="isNoData"
            :error="error"
            :is-higeht-max="true"
            :adv-list="advList"
            :hasAdvPagination="true"
            className="HotRankingPage"
          >
            <div v-if="item.kind == 0 || item.kind == 5">
              <div v-if="videoList && videoList.length > 0">
                <Waterfall
                  :list="videoList"
                  :key="item.kind"
                  :kind="item.kind"
                />
              </div>

              <!-- 猜你想看 -->
              <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                <div class="guessTitle">
                  <span>猜你想看</span>
                </div>
                <Waterfall
                  :list="videoHotList"
                  :key="item.kind"
                  :kind="item.kind"
                />
              </div>
            </div>
            <!-- 横板 -->
            <div v-else-if="activeName == 2">
              <div v-if="videoList && videoList.length > 0">
                <TransverselyTwoList
                  :isEdit="isEdit"
                  @del="del"
                  :videoList="videoList"
                ></TransverselyTwoList>
              </div>

              <!-- 猜你想看 -->
              <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                <div class="guessTitle">
                  <span>猜你想看</span>
                </div>
                <TransverselyTwoList
                  :videoList="videoHotList"
                ></TransverselyTwoList>
              </div>
            </div>
            <!-- 竖版 -->
            <div v-else>
              <div v-if="videoList && videoList.length > 0">
                <VerticleThreeList
                  :isEdit="isEdit"
                  @del="del"
                  :videoList="videoList"
                ></VerticleThreeList>
              </div>

              <!-- 猜你想看 -->
              <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                <div class="guessTitle">
                  <span>猜你想看</span>
                </div>
                <VerticleThreeList
                  :videoList="videoHotList"
                ></VerticleThreeList>
              </div>
            </div>
          </PullRefresh>
          <Loading v-else />
        </div>
      </van-tab>
    </van-tabs>

    <!-- <div class="delDialog">
      <van-dialog v-model="delShow" show-cancel-button @confirm="delCollect">
        <img src="@/assets/png/dialogClose.png" class="delDialogBtn" @click="delShow = false">
        <div class="dialogContent">
          <span>您是否移除该内容？</span>
        </div>
      </van-dialog>
    </div> -->
  </div>
</template>

<script>
import { queryBroughtList, queryHotInfoList } from "@/api/video";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import PostList from "./components/PostList.vue";
import Waterfall from "@/components/Waterfall";
export default {
  name: "purchased",
  components: {
    PostList,
    Waterfall,
    PullRefresh,
    Loading,
    VerticleThreeList,
    TransverselyTwoList,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      activeName: 0,
      topicId: "",
      zone: "",
      titleName: "",
      videoList: [],
      videoHotList: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [],
      error: false,
      delShow: false,
      delItem: null,
      navList: [
        {
          title: "动漫",
          kind: 0,
        },
        {
          title: "漫画",
          kind: 1,
        },
        {
          title: "真人",
          kind: 3,
        },
        {
          title: "写真",
          kind: 5,
        },
        {
          title: "帖子",
          kind: 99,
        },
        {
          title: "小说",
          kind: 4,
        },
        // {
        //   title: "原作",
        //   kind: 6,
        // },
        {
          title: "剧场",
          kind: 8,
        },
      ],
      isTypeLoading: 0, //书籍加载，如果加载完，就加载推荐（0：加载书籍数据，1：加载推荐数据）
      isEdit: false,
    };
  },
  created() {
    this.getCollectInfoList("refresh");
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    /**
     * 我的书架数据列表
     * @param type
     * @returns {Promise<void>}
     */
    async getCollectInfoList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        kind: this.navList[this.activeName].kind,
      };
      let res = await this.$Api(queryBroughtList, req);

      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      try {
        if (res && res.code == 200) {
          if (res.data.MediaList && res.data.MediaList.length > 0) {
            if (type == "refresh") {
              this.videoList = res.data.MediaList;
            } else {
              this.videoList = this.videoList.concat(res.data.MediaList);
            }
          } else {
            this.videoList = [];
          }

          // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
          //     this.isNoData = true;
          // }
          if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
            // this.finished = true;
            this.isTypeLoading = 1;
            this.getHotInfoList("refresh");
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    async getHotInfoList(type) {
      /**
       * 猜你喜欢
       * @type {{pageNumber: number, kind: number, pageSize: number}}
       */
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        kind: this.navList[this.activeName].kind,
      };
      let res = await this.$Api(queryHotInfoList, req);

      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      if (res && res.code == 200) {
        if (res.data.MediaList && res.data.MediaList.length > 0) {
          if (type == "refresh") {
            this.videoHotList = res.data.MediaList;
          } else {
            this.videoHotList = this.videoHotList.concat(res.data.MediaList);
          }
        }

        // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
        //     this.isNoData = true;
        // }
        if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
          this.finished = true;
        }
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.getCollectInfoList("refresh");
      // this.getHotInfoList('refresh');
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      if (this.isTypeLoading == 0) {
        this.getCollectInfoList();
      } else {
        this.getHotInfoList();
      }
    },
    clickTag(index) {
      this.videoHotList = [];
      if (index != null) {
        this.activeName = index;
      }
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.getCollectInfoList("refresh");
      // this.getHotInfoList('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
.purchased {
  height: 100%;
  color: #000;

  .navBar {
    height: 44px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: #333333;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .navTabs {
    .navTabTitle {
      width: 46px;
      height: 26px;
      font-family: "Dream Han Sans TC";
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 18px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navTabTitleActive {
      color: #555;
      background: url("../../../assets/png/tabBg.png");
      background-size: 100% 100%;
    }

    ///deep/ .van-tabs__nav--complete {
    //    padding-left: 12px;
    //}
    //
    ///deep/ .van-tabs__wrap--scrollable .van-tab{
    //    padding: 0;
    //    padding-right:21px;
    //}
    //
    /deep/ .van-tabs__wrap {
      width: 342px;
      height: 33px;
      margin: 20px auto;
      display: flex;
      justify-content: center;
      background: #f0f0f3;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 8px;
    }

    /deep/ .van-tabs__line {
      width: 18px !important;
      height: 2px !important;
      border-radius: 1px !important;
      background: #94d6da !important;
      bottom: 18px !important;
      display: none;
    }

    /deep/ .van-tab {
      padding: 0;
      // width: 46px;
      // height: 26px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // margin-right: 18px;
    }
    /deep/ .van-tabs__nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .bookshelfContent {
      height: calc(100vh - 120px);
      padding: 0 12px 0 12px;
      overflow-y: auto;

      .guess {
        .guessTitle {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 9px;
        }
      }
    }
  }

  .content {
    //width: 100%;
    height: calc(100% - 44px);
    padding: 0 12px;
  }

  .delDialog {
    /deep/ .van-dialog {
      background: rgb(255, 255, 255);
    }

    .delDialogBtn {
      width: 10.6px;
      height: 10.6px;
      position: absolute;
      right: 12px;
      top: 12px;
    }

    /deep/ .van-button--default {
      background: rgb(255, 255, 255);
    }

    /deep/ .van-dialog__cancel {
      color: rgb(153, 153, 153);
    }

    /deep/ .van-hairline--left {
      color: rgb(113, 69, 231);
    }

    /deep/ [class*="van-hairline"]::after {
      border: 1px solid rgb(233, 233, 233);
    }

    .dialogContent {
      font-size: 18px;
      color: rgb(0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 70px;
    }
  }
}
</style>
